import { useEffect, useState } from 'react'

export default function Counter ({ finalValue }: { finalValue: number }) {
    const [count, setCount] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (count < finalValue) {
                setCount(prevCount => prevCount + 1)
            } else {
                clearInterval(interval);
            }
        }, 20)

        return () => clearInterval(interval)
    }, [count, finalValue])

    return (
        <div className='flex lex justify-center items-center'>
            <h1 className="text-light xl:text-min 2xl:text-description font-light tracking-tight text-primary-800">+</h1>
            <h1 className="text-light xl:text-min 2xl:text-description  font-bold tracking-tight text-primary-800">{count}</h1>
        </div>
    )
}
