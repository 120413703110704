import Counter from "./counter";

export default function NumbersResults() {
    return (
        <div className="flex justify-center items-center gap-x-5">
            <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="1000"
            >
                <Counter finalValue={240} />
                <h4 className="text-textlight font-light tracking-tight text-primary-800">Clientes satisfeitos</h4>
            </div>
            <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="1200"
            >
                <Counter finalValue={300} />
                <h4 className="text-textlight font-light tracking-tight text-primary-800">Projetos entregues</h4>
            </div>
            <div
                data-aos="fade-right"
                data-aos-duration="800"
                data-aos-delay="1400"
            >
                <Counter finalValue={25} />
                <h4 className="text-textlight font-light tracking-tight text-primary-800">Anos de atuação</h4>
            </div>
        </div>
    )
}