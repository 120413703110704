import { useEffect, useState } from "react"

import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui/dialog"

import { Slider } from "../ui/slider"

import simulationBG from '../../assets/Simulation.png'
import simulationBGMobile from '../../assets/SimulationMobile.png'

import { FaArrowDown } from "react-icons/fa6"

import { Button } from "../ui/button"

export default function Simulation() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [bgImage, setBgImage] = useState(simulationBG)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth

            if (isMobile < 519) {
                setBgImage(simulationBGMobile)
            } else {
                setBgImage(simulationBG)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [sliderValue, setSliderValue] = useState<number>(500)
    const [valorTotal, setValorTotal] = useState(60)


    useEffect(() => {
        const newValueTotal = 60 + (sliderValue - 100) * 0.6;
        setValorTotal(newValueTotal);
    }, [sliderValue])

    const capturedValueSlide = (event: any) => {
        setSliderValue(event.target.value)
    }

    const corPersonalizada = "#0061FE"

    return (
        <div
            className="flex flex-col w-full justify-center items-center lg:p-0 p-4 mb-20 gap-y-10"
            style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', height: isMobile ? '90vh' : '100vh' }}
        >
            <h1 className="text-description xl:text-subtitle lg:text-title font-bold tracking-tight text-white text-center">A melhor <span style={{ color: corPersonalizada }}>solução</span> Cloud<br /><span style={{ color: corPersonalizada }}>Backup</span> para sua empresa!</h1>
            <div className="flex flex-col w-full lg:w-6/12 px-2 lg:px-0">
                <h4 className="text-textmin font-light text-justify tracking-tight text-white">Faça backup de um número ilimitado de endpoints, servidores físicos, virtuais, bancos de dados, aplicativos e cargas de trabalho na nuvem.Ganhe segurança, organização e agilidade em seu negócio.</h4>
            </div>
            <Dialog>
                <DialogTrigger className="flex flex-col w-full justify-start items-center lg:gap-x-5 gap-y-5">
                    <Button variant="default" className="font-semibold text-[18px]">Clique aqui e Simule agora!</Button>
                    <FaArrowDown size="30px" color="#0061FE" />
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px] bg-white">
                    <DialogHeader className="flex flex-col justify-center items-center">
                        <DialogTitle>Simulação do EasyBackup</DialogTitle>
                        <DialogDescription>
                            A melhor solução de Cloud Backup para sua empresa!
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col w-full justify-center items-center gap-y-5">
                        <h2 className="description font-semibold  tracking-tight text-primary-900 hidden lg:block">{sliderValue}GB</h2>
                        <input
                            type="range"
                            min={100}
                            max={1000}
                            step={1}
                            value={sliderValue}
                            onChange={capturedValueSlide}
                            className="slider-thumb appearance-none w-full h-1 bg-secondary-50 outline-none cursor-pointer"
                            style={{
                                background: `linear-gradient(to right, #0061FE 0%,  ${sliderValue}%, #5998FF ${sliderValue}%, #4299e1 100%)`
                            }}
                        />
                        <div className="flex flex-col w-full justify-center items-center">
                            <h4 className="textlight font-semibold tracking-tight text-primary-900">Valor total:</h4>
                            <h2 className="description font-semibold tracking-tight text-primary-900">{valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}</h2>
                        </div>
                    </div>
                    <DialogFooter>
                        <DialogClose>
                            <Button variant="outline">Fechar</Button>
                        </DialogClose>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}