import { useEffect, useState } from 'react'

import dataCenterImage from '../../assets/DataCenter.png'
import bgBackup from '../../assets/backupBG.png'
import bgBackupMobile from '../../assets/backupBGmobile.png'

import { Button } from '../ui/button'

import { FaArrowDown, FaArrowRight } from "react-icons/fa6"

export function IntroBackupPage() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const [bgImage, setBgImage] = useState(bgBackup)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        };

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 519) {
                setBgImage(bgBackupMobile);
            } else {
                setBgImage(bgBackup);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const corPersonalizada = "#0061FE"

    return (
        <div
            className="
        font-poppinsw-full 
        flex 
        lg:flex-row 
        flex-col
        justify-center
        items-center 
        transition-all
        lg:gap-14
        gap-0
        "
            style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: isMobile ? '120vh' : '100vh',
            }}
        >
            <div className="flex flex-col justify-center lg:items-start items-center p-5 lg:h-full h-auto lg:gap-y-10 gap-y-10">
                <h1 className="text-subtitle lg:text-title font-bold tracking-tight text-white">EasyBackup</h1>
                <h2 className="text-description text-center xl:text-start font-medium tracking-tight text-white">Soluções de <span style={{ color: corPersonalizada }}>armazenamento</span><br />em <span style={{ color: corPersonalizada }}>nuvem</span> de alta performance!</h2>
                <h4 className="text-[16px] xl:text-light text-center xl:text-start font-light tracking-tight text-white">Hospede com segurança e alta performance<br />na maior e mais qualificada infraestrutura<br />de data centers em operação no Brasil.</h4>
                <div className="flex lg:flex-row flex-col w-full justify-start items-center lg:gap-x-5 gap-y-5 ">
                    <a href="#benefitsBackup">
                        <Button variant="default" className="font-semibold text-[18px]">CONHEÇA NOSSAS SOLUÇÕES</Button>
                    </a>
                    <FaArrowRight size="30px" color="#0061FE" className="hidden lg:block" />
                    <FaArrowDown size="30px" color="#0061FE" className="lg:hidden" />
                </div>
            </div>
            <div className="flex justify-center lg:items-end items-end lg:w-[45%] w-auto lg:pt-0 pt-20">
                <img src={dataCenterImage} alt="Sticker" width='80%' />
            </div>
        </div>
    )
}