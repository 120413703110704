// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide"
import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import AmazonLogo from "../../assets/amazonLogo.png"
import OracleLogo from "../../assets/oracleLogo.png"
import WindowsServeLogo from "../../assets/widonsServerLogo.png"
import LinuxLogo from "../../assets/linuxLogo.png"
import Api from '../../assets/API.png'
import Api2 from '../../assets/API_2.png'
import { useEffect, useRef, useState } from "react"
import BackgrounCloudServices from "../../assets/BackgroundCloudServices.png"
import BackgrounCloudServicesMobile from "../../assets/BackgroundCloudService.png"

import { FaChevronRight, FaChevronLeft } from "react-icons/fa"

import { Button } from "../ui/button"

const carouselService = [
  {
    serverImageOne: AmazonLogo,
    serverImageTwo: OracleLogo,
    altImageImageOne: "ServerCloud",
    altImageImageTwo: "ServerCloud",
    title: "Server Cloud",
    description:
      "Desfrute do potencial da Oracle Cloud e da Amazon AWS para impulsionar seu negócio. Com uma vasta gama de recursos, esses provedores garantem escalabilidade, confiabilidade e inovação para suas operações.",
  },
  {
    serverImageOne: WindowsServeLogo,
    serverImageTwo: LinuxLogo,
    altImageImageOne: "Sistemas Operacionais",
    altImageImageTwo: "ServerCloud",
    title: "Sistemas Operacionais",
    description:
      "Utilizamos sistemas operacionais Windows e Linux para potencializar suas operações de servidor. Esses sistemas garantem eficiência, estabilidade e inovação para atender às necessidades do seu negócio.",
  },
  {
    serverImageOne: Api,
    serverImageTwo: Api2,
    altImageImageOne: "AltTextImage",
    altImageImageTwo: "ServerCloud",
    title: "APIs",
    description:
      "Desenvolvemos APIs personalizadas para atender às suas demandas específicas. Nossa abordagem garante flexibilidade, confiabilidade e escalabilidade para impulsionar a eficiência e a inovação em sua plataforma.",
  },
];

export default function CloudServices() {
  const splideRef = useRef<Splide | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleNextSlide = () => {
    const splideInstance = splideRef.current?.splide;

    if (splideInstance) {
      splideInstance.go("+1");
    }
  };

  const handlePrevSlide = () => {
    const splideInstance = splideRef.current?.splide;

    if (splideInstance) {
      splideInstance.go("-1");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="cloudservices"
      className="h-full lg:p-20 p-5 gap-10 flex flex-col items-center"
      style={{
        backgroundImage: `url(${isMobile ? BackgrounCloudServicesMobile : BackgrounCloudServices
          })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: isMobile ? "auto" : "100vh",
      }}
    >
      <h1
        data-aos="fade-up"
        data-aos-duration="1500"
        className="text-description lg:text-title text-center font-bold text-secondary-100"
      >
        Data base Data center<br /> Cloud services
      </h1>
      <div
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-delay="500"
        data-aos-offset="0"
        className="h-auto lg:w-[160vh] flex flex-row justify-center items-center"
      >
        <Button
          onClick={handlePrevSlide}
          variant="ghost"
          className="hidden lg:block"
        >
          <FaChevronLeft color="#0061FE" size="15px" />
        </Button>
        <Splide
          ref={splideRef}
          options={{
            rewind: true,
            height: "22em",
            perPage: isMobile ? 1 : 2,
            drag: isMobile ? false : true,
            wheel: false,
            autoplay: false,
            Button: true,
            arrow: true,
            pagination: false,
            direction: isMobile ? "ttb" : "ltr",
          }}
        >
          {carouselService.map((item, index) => (
            <SplideSlide
              key={index}
              className="flex flex-row justify-center items-center"
            >
              <div className="grid grid-rows-4 max-w-lg bg-white h-80 border border-gray-200 rounded-2xl shadow dark:bg-gray-800 dark:border-gray-700 p-6">
                <h5 className="row-span-1 w-full text-3xl font-bold tracking-tight text-gray-900 h-fit">
                  {item.title}
                </h5>
                <p className="row-span-3 font-normal text-gray-700 dark:text-gray-400 text-justify">
                  {item.description}
                </p>
                <div className="row-span-1 flex gap-4">
                  <img
                    className="rounded-t-lg h-10 w-auto"
                    src={item.serverImageOne}
                    alt={item.altImageImageOne}
                  />
                  <img
                    className="rounded-t-lg h-10 w-auto"
                    src={item.serverImageTwo}
                    alt={item.altImageImageOne}
                  />
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
        <Button
          onClick={handleNextSlide}
          variant="ghost"
          className="hidden lg:block"
        >
          <FaChevronRight color="#0061FE" size="15px" />
        </Button>
      </div>
    </div>
  );
}
