import { useEffect, useState } from "react"

import Beneficios from '../../assets/BeneficiosBakcup.png'
import BeneficiosMobile from '../../assets/BeneficiosBakcupMobile.png'

export default function Benefits() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [bgImage, setBgImage] = useState(Beneficios)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth

            if (isMobile < 519) {
                setBgImage(BeneficiosMobile)
            } else {
                setBgImage(Beneficios)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const CardsBenefits = [
        {
            title: 'Cloud Backup',
            description: `A vantagem do backup na nuvem é que ele está sempre disponível. Diferente de um servidor físico 
            da empresa, que pode parar de funcionar ou ser desligado para uma manutenção, o backup na nuvem está sempre 
            disponível para que as informações sejam acessadas a qualquer momento, de qualquer lugar.`
        },
        {
            title: 'Segurança',
            description: `O backup na nuvem possibilita que toda a logística da informação seja feita de forma automatizada 
            e digital. Dessa forma, reduz riscos de esquecimento ou violação da informação. A segurança fica armazenada 
            em datacenter seguro e espalhado em localizações que garantem que a informação continuará existindo.`
        },
        {
            title: 'Disponibilidade',
            description: `A solução de backup em nuvem da INTERAS mantém as últimas versões de arquivos salvos em nuvem, 
            em caso de infecção no equipamento local, basta restaurar a última versão do arquivo da nuvem. Não perca mais 
            arquivos importantes, previna-se e restaure sua máquina em até 30 versões anteriores.`
        },
        {
            title: 'Performance',
            description: `A solução de backup na nuvem que também armazena informações em cache local, tem as informações 
            que podem ser acessadas com mais frequência no ambiente local, e as informações que precisam ser arquivadas 
            com segurança na nuvem. Melhore agora o processamento de dados da sua empresa com o EasyBackup.`
        },
        {
            title: 'Flexibilidade',
            description: `O backup na nuvem permite acesso remoto a dados, possibilitando que pessoas fora da empresa, 
            em viagens, home office ou em qualquer lugar, continuem trabalhando mesmo se não puderem acessar o prédio da empresa.`
        },
        {
            title: 'Ilimitado',
            description: `Recursos físicos limitados e equipamentos requerem espaço. O backup na nuvem permite escalabilidade 
            sem limitações de espaço físico ou orçamento, facilitando o crescimento contínuo.`
        },
    ]

    return (
        <div
            className="
            font-poppinsw-full 
            flex 
            flex-col  
            justify-center
            items-center
            "
            id="benefitsBackup"
            style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', height: isMobile ? '180vh' : '150vh' }}
        >
            <div className="flex flex-col w-auto h-auto justify-center items-center gap-y-20">
                <h1 className="text-title font-bold tracking-tight text-white text-center hidden lg:block">Quais são os benefícios<br />do EasyBackup?</h1>
                <div className="flex flex-wrap max-w-[1250px] h-auto justify-center items-center gap-5">
                    {CardsBenefits.map((item, index) => (
                        <div
                            data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="500"
                            data-aos-offset="0"
                            key={index}
                            className="flex max-w-[400px] h-60 bg-white border border-gray-200 rounded-2xl shadow dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-5">
                                <a href="#">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                                </a>
                                <h4 className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-justify">
                                    {item.description}
                                </h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}