import { useEffect, useState } from "react"
import { FaArrowDown, FaArrowRight } from "react-icons/fa6"
import { handleResize } from "src/Utils/useHandleResize"
import bgHome from 'src/assets/HomePage/Background.png'
import bgHomeMobile from 'src/assets/HomePage/BackgroundMobile.png'
import { Button } from "../../../components/ui/button"
import { ThreeDCardDemo } from "./image3D"
import NumbersResults from "./numbersResults"

export default function Home() {

    const isMobile = window.innerWidth < 720

    const [bgImage, setBgImage] = useState(window.innerWidth < 519 ? bgHomeMobile : bgHome)

    useEffect(() => {
        handleResize({
            setBgImage: setBgImage,
            bgDesktop: bgHome,
            bgMobile: bgHomeMobile
        })
    }, [])

    return (
        <div className="flex lg:flex-row flex-col-reverse justify-evenly items-center transition-all"
            id="inicio"
            data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-duration="800" data-aos-delay="400" data-aos-offset="0"
            style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', height: isMobile ? 'auto' : '100vh' }}
        >
            <div className="flex flex-col h-full justify-around lg:justify-center items-center lg:items-start text-center overflow-hidden p-5 gap-y-10 lg:gap-y-10">
                <h1 
                    className="text-secondy xl:text-subtitle 2xl:text-title text-primary-800 font-bold leading-[60px] xl:leading-[80px] tracking-tight">
                    Gestão em<br /><span className="text-secondary-100">tecnologia</span>
                </h1>
                <h4 data-aos="fade-down" data-aos-easing="linear" data-aos-duration="800" data-aos-delay="900"
                    className="text-minlight xl:text-light font-light tracking-tight text-justify text-primary-800">
                    Aumente a produtividade e a qualidade das<br />informações da sua empresa com as soluções<br />da Interas!
                </h4>
                <div className="flex lg:flex-row flex-col justify-center items-center lg:gap-x-5 gap-y-5 ">
                    <a href="#services">
                        <Button
                            data-aos="flip-up" data-aos-easing="linear" data-aos-duration="800" data-aos-delay="800"
                            variant="default"
                            className="font-semibold text-[14px]">
                            CONHEÇA NOSSAS SOLUÇÕES
                        </Button>
                    </a>
                    <FaArrowRight data-aos="fade-right" data-aos-delay="1200" data-aos-duration="800" size="30px" color="#0061FE" className="hidden lg:block" />
                    <FaArrowDown data-aos="fade-right" data-aos-delay="1200" data-aos-duration="800" size="30px" color="#0061FE" className="lg:hidden" />
                </div>
                <div>
                    <NumbersResults />
                </div>
            </div>
            <div className="flex justify-center lg:items-end items-center">
                <ThreeDCardDemo />
            </div>
        </div>
    )
}