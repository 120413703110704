import Desenvolvimento from '../assets/Tecnologia.png'
import Suporte from '../assets/Suporte.png'
import Infra from '../assets/Infra.png'

export const CoverInfoSolutions = [
    {
        image: Desenvolvimento,
        title: 'Desenvolvimento de softwares',
        description: 'Utilizando as melhores tecnologias',
    },
    {
        image: Suporte,
        title: 'Equipe de suporte técnico',
        description: 'Ajudando nossos clientes da melhor forma',
    },
    {
        image: Infra,
        title: 'Equipe de Infraestrutura',
        description: 'Garantindo uma base sólida para o seu sucesso digital.',
    },
]