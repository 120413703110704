import FormFunctions from "./FormFunctions";

export default function Contato() {

    return (
        <div id="contato" className="flex flex-col-reverse lg:flex-row w-full h-auto justify-center items-center gap-x-20 gap-y-10 py-20">
            <div
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="300"
                data-aos-duration="1200"
                data-aos-offset="0"
                className="flex flex-col w-11/12 xl:w-4/12 2xl:w-3/12 h-auto bg-primary-800 p-10 rounded-3xl gap-y-5 z-30">
                <h1 className='text-secondy font-bold text-white'>Fale conosco</h1>
                <FormFunctions />
            </div>
            <div className="flex flex-col w-11/12 xl:w-4/12 h-auto gap-y-5 p-4">
                <h1 data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1200" className='text-subtitle xl:text-secondy font-bold text-center xl:text-start'>Entre em contato</h1>
                <div className="flex flex-col w-full  2xl:w-10/12 h-auto gap-y-5 overflow-hidden">
                    <h4 data-aos="fade-down" data-aos-delay="300" data-aos-duration="1200" className='text-light xl:text-light text-secondary-50 font-light text-justify'>
                        A Interas Tecnologia tem o prazer de oferecer soluções inovadoras e consultoria especializada para atender às necessidades da sua empresa.
                    </h4>
                    <h4 data-aos="fade-zoom-in" data-aos-delay="300" data-aos-easing="ease-in-back" data-aos-offset="0" className='text-light xl:text-light text-secondary-50 font-light text-justify'>
                        Tratamos cada projeto com o máximo de seriedade e dedicação, garantindo a excelência desde a pesquisa até a execução.
                    </h4>
                    <h4 data-aos="fade-up" data-aos-delay="300" data-aos-duration="1200" className='text-light xl:text-light text-secondary-50 font-light text-justify'>
                        Não deixe de nos contatar! Preencha agora o formulário, e nossa equipe entrará em contato com você o mais breve possível para impulsionar o seu sucesso.
                    </h4>
                </div>
            </div>
        </div>
    )
}