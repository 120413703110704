import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { useRef, useState } from "react";

import Falha from '../../assets/Falha.png'
import hacker from '../../assets/hacker.png'
import Roubo from '../../assets/roubo.png'


export default function Causes() {

    const splideRef = useRef<Splide | null>(null)

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    const cardCauses = [
        {
            titulo: 'Ataque de Hackers',
            description: 'Vulnerabilidades de segurança online, como senhas fracas e software desatualizado, atraem hackers em busca de dados valiosos.',
            image: hacker,
        },
        {
            titulo: 'Falha Humana',
            description: 'Erros como exclusões acidentais e compartilhamento não autorizado de dados contribuem para perdas.',
            image: Falha,
        },
        {
            titulo: 'Perda ou roubo de dados',
            description: 'Falta de segurança física, como armazenamento inadequado de dispositivos, pode levar à perda ou roubo de dados.',
            image: Roubo,
        },
    ]

    return (
        <div
            className="
            font-poppinsw-full 
            flex 
            lg:flex-row 
            flex-col-reverse 
            justify-evenly 
            items-center 
            transition-all
            "
        >
            <div className="flex flex-col justify-center items-center h-auto lg:p-10 p-0 gap-24">
                <h1 className="text-description xl:text-subtitle font-bold tracking-tight text-center text-primary-900">
                    Principais causas de<br />vazamentos de dados
                </h1>
                <Splide
                    ref={splideRef}
                    options={{
                        rewind: true,
                        height: '28em',
                        perPage: isMobile ? 1 : 2,
                        drag: isMobile ? false : true,
                        wheel: false,
                        autoplay: true,
                        Button: true,
                        arrow: true,
                        pagination: isMobile ? false : true,
                        direction: isMobile ? 'ttb' : 'ltr',
                    }}
                    className="custom-splide custom-arrow lg:pt-0 pt-10 lg:pb-0 pb-10"
                >
                    {cardCauses.map((item, index) => (
                        <SplideSlide
                            className="flex flex-row w-full h-auto justify-center items-center"
                            key={index}
                        >
                            <div className="grid grid-rows-4 lg:max-w-lg max-w-sm h-80 bg-white border border-gray-200 rounded-2xl shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex flex-col gap-y-2 p-5">
                                    <a href="#">
                                        <h1 className="mb-2 text-description font-bold tracking-tight text-gray-900 dark:text-white">{item.titulo}</h1>
                                    </a>
                                    <p className="mb-3 text-[16px] lg:w-96 w-auto text-gray-700 dark:text-gray-400 text-justify">
                                        {item.description}
                                    </p>
                                    <div className="flex flex-row lg:justify-end justify-center items-end w-full mb-[-20px]">
                                        <img className="rounded-t-lg" src={item.image} width="25%" />
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>

            </div>
        </div>
    )
}