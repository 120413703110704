import { useEffect, useState } from 'react';
import dataCenterEasyBackup from '../../assets/DataCenterEasyBackup.png'

export default function EasyBackup() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex lg:flex-row flex-col w-full h-auto">
            <div className="flex w-auto lg:max-w-[50%] max-w-[100%] lg:justify-start justify-center lg:items-start items-center">
                <img src={dataCenterEasyBackup} width={isMobile ? '100%' : '80%'} alt='EasyBackup' />
            </div>
            <div className="flex flex-col justify-center lg:items-start items-center lg:p-0 p-5 w-auto lg:max-w-[33%] max-w-[100%] h-auto gap-y-10">
                <h1 className="text-description xl:text-subtitle text-center lg:text-start font-bold tracking-tight text-primary-900">O que é<br />EasyBackup?</h1>
                <span className='w-20 h-1 bg-black' />
                <h3 className="text-light font-light text-justify tracking-tight text-primary-900">
                    EasyBackup é uma categoria de software ou serviço simplificado projetado para facilitar o processo de backup de dados.
                    Com interfaces fáceis de usar e configurações simplificadas, permite que até mesmo usuários inexperientes protejam seus
                    dados importantes contra perda. Os backups podem ser feitos localmente ou na nuvem, oferecendo uma maneira conveniente
                    e eficaz de garantir a segurança dos dados.
                </h3>
                <div className='flex w-full lg:justify-end justify-center'>
                    <span className='flex justify-end items-end w-20 h-1 bg-black'></span>
                </div>
            </div>
        </div>
    )
}