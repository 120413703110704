// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import LeroAppScreen from "../../assets/phone-lero.jpeg";
import App98Screen from "../../assets/phone-98.jpeg";
import MockupAppScreen from "../../assets/phone-mockup.jpeg";
import ChamadoAppScreen from "../../assets/phone-chamado.png";
import AppScreen from "../../assets/phone-app.jpeg";
import { useEffect, useRef, useState } from "react";
import "./style.css";

export default function AppCarousel() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const splideRef = useRef<Splide | null>(null)

  const CarrosselImages = [
    {
      image: LeroAppScreen,
    },
    {
      image: App98Screen,
    },
    {
      image: MockupAppScreen,
    },
    {
      image: ChamadoAppScreen,
    },
    {
      image: AppScreen,
    },
  ]

  return (
    <div className="h-auto lg:h-screen w-full lg:w-10/12 flex justify-center items-start lg:items-center p-16 lg:p-0 -mt-4 lg:mt-0 mb-4 lg:mb-0">
      <Splide
        ref={splideRef}
        options={{
          type: 'loop',
          perPage: isMobile ? 1 : 2,
          focus: 'center',
          autoplay: true,
          interval: 8000,
          flickMaxPages: 3,
          updateOnMove: true,
          pagination: false,
          padding: '10%',
          throttle: 300,
          breakpoints: {
            1440: {
              perPage: 1,
              padding: '20%'
            }
          }
        }}
        className="carrossel-splide-custom"
      >
        {CarrosselImages.map((item, index) => (
          <SplideSlide key={index} className="app-carousel flex flex-col justify-center items-center h-full lg:h-auto">
            <img src={item.image} alt="carrossel" className="h-auto rounded-2xl " />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
