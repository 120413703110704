import PGPrime from 'src/assets/HomePage/Pgprime.png'
import EnerOne from 'src/assets/HomePage/EnerOne.png'
import TokStok from 'src/assets/HomePage/TokStock.png'
import Icone from 'src/assets/HomePage/Icone.png'
import LigaCancer from 'src/assets/HomePage/LigaContraCancer.png'
import FatGuys from 'src/assets/HomePage/FatGuys.png'
import Coopertec from 'src/assets/Coopertec.png'
import IRTech from 'src/assets/IRTECH.png'

export const CaseSlides = [
    {
        images: Coopertec,
        title: 'Coopertec Infraestrutura',
        description: 'Empresa de infraestrutura',
        link: 'https://www.coopertec.com/',
    },
    {
        images: EnerOne,
        title: 'Ener-One',
        description: 'Promove economia e sustentabilidade para médias e grandes empresas por meio da geração fotovoltaica.',
        link: 'https://ener-one.com.br/',
    },
    {
        images: FatGuys,
        title: 'FATGUYS',
        description: 'FAT GUYS - Natal',
        link: 'https://franquia.fatguys.com.br/',
    },
    {
        images: Icone,
        title: 'Ícone Energia',
        description: 'Serviços para energia renovável',
        link: 'https://iconeenergia.com.br/',
    },
    {
        images: IRTech,
        title: 'IR Tech',
        description: 'Desenvolvimento de soluções em controle e automação industrial',
        link: 'https://iconeenergia.com.br/',
    },
    {
        images: LigaCancer,
        title: 'Integração Tecnológica - Liga',
        description: 'Hospitais e atividades de atenção à saúde humana',
        link: 'https://ligacontraocancer.com.br/',
    },
    {
        images: PGPrime,
        title: 'Grupo PG Prime',
        description: 'Grupo de concessionárias especializadas no segmento premium automobilístico',
        link: 'https://www.pgprime.com.br/',
    },
    {
        images: TokStok,
        title: 'TOK&STOK',
        description: 'Fabricação de móveis e artigos de decoração',
        link: 'https://www.tokstok.com.br/?utm_source=google&utm_medium=cpc&utm_campaign=948506802&utm_term=48794556903&gad_source=1&gclid=Cj0KCQjwwMqvBhCtARIsAIXsZpbqTwnh9XebMXyX0Yhw1Cv4SkMDn95gq2ypeQ7r120ndfeFJdU4KtoaApgoEALw_wcB',
    }
]