
import Contato from "src/components/Contato"
import { IntroBackupPage } from "src/components/PageBackup/IntroBackupPage"
import Simulation from "src/components/PageBackup/Simulation"
import BackupManager from "src/components/PageBackup/backupManager"
import Benefits from "src/components/PageBackup/benefits"
import BestClouds from "src/components/PageBackup/bestClouds"
import Causes from "src/components/PageBackup/causes"
import DataCenters from "src/components/PageBackup/dataCenters"
import EasyBackup from "src/components/PageBackup/easybackup"
import Functionalities from "src/components/PageBackup/functionalities"
import WebPortal from "src/components/PageBackup/webPortal"
import SmoothScroll from "src/components/SmoothScroll"

export function BackupPage() {
    return (
        <>
                <IntroBackupPage />
                <Causes />
                <EasyBackup />
                <Benefits />
                <WebPortal />
                <Functionalities />
                <BestClouds />
                <Simulation />
                <DataCenters />
                <Contato />
        </>
    )
}