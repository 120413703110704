import { Button } from "../ui/button";

import axios from "axios"
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useToast } from "src/components/ui/use-toast";
import { Input } from "../ui/input";

interface FormData {
    name: string;
    email: string;
    phone: string;
    enterprise: string;
    message: string;
}

const formSchema = z.object({
    name: z.string().min(3, {message: 'Preencha o campo obrigatório'}),
    email: z.string().min(3, {message: 'Preencha o campo obrigatório'}).email('Email inválido'),
    phone: z.string().min(3, {message: 'Preencha o campo obrigatório'}),
    enterprise: z.string().min(3, {message: 'Preencha o campo obrigatório'}),
    message: z.string().min(3, {message: 'Preencha o campo obrigatório'})
})

export default function FormFunctions() {

    const { toast } = useToast()

    const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
        resolver: zodResolver(formSchema)
    })

    async function sendEmail(formData: FormData) {
        try {
            const response = await axios.post('https://api.interas.app/coopertec/send-form', formData);
            console.log(response.data);
            toast({
                title: 'E-mail enviado com sucesso!',
                description: 'Em breve entraremos em contato.',
                type: 'background',
                variant: 'Success',
            })

            reset()
            console.log("Form reset successfully!");
        } catch (error) {
            console.error(error);
            reset()
            toast({
                title: 'Ops, algo deu errado!',
                description: 'Por favor, preencha todos os campos corretamente.',
                type: 'background',
                variant: 'destructive'
            });
        }
    }

    return (
        <form onSubmit={handleSubmit(sendEmail)} className="flex flex-col w-full h-auto gap-y-4">
            <div className="flex flex-col text-white gap-1">
                <p className="text-light text-white font-light">Nome:</p>
                <Input
                    placeholder="Nome"
                    className="bg-transparent placeholder-white rounded-full focus:border-2 focus:border-secondary-100"
                    {...register("name")}
                />
                {errors.name && errors.name.type === "too_small" && (
                    <span className="text-primary-50">{errors.name.message}</span>
                )}
            </div>
            <div className="flex flex-col text-white gap-1">
                <p className="text-light text-white font-light">E-mail:</p>
                <Input
                    placeholder="E-mail"
                    className="bg-transparent placeholder-white rounded-full focus:border-2 focus:border-secondary-100"
                    {...register("email")}
                />
                {errors.email && (errors.email.type === "too_small" || errors.email.type === "invalid_string") && (
                    <span className="text-primary-50">{errors.email.message}</span>
                )}
            </div>
            <div className="flex flex-col text-white gap-1">
                <p className="text-light text-white font-light">Empresa:</p>
                <Input
                    placeholder="Empresa"
                    className="bg-transparent placeholder-white rounded-full focus:border-2 focus:border-secondary-100"
                    {...register("enterprise")}
                />
                {errors.enterprise && errors.enterprise.type === "too_small" && (
                    <span className="text-primary-50">{errors.enterprise.message}</span>
                )}
            </div>
            <div className="flex flex-col text-white gap-1">
                <p className="text-light text-white font-light">Telefone:</p>
                <Input
                    placeholder="Telefone"
                    type="number"
                    className="bg-transparent placeholder-white text-white rounded-full focus:border-2 focus:border-secondary-100"
                    {...register("phone")}
                />
                {errors.phone && errors.phone.type === "too_small" && (
                    <span className="text-primary-50">{errors.phone.message}</span>
                )}
            </div>
            <div className="flex flex-col text-white gap-1">
                <p className="text-light text-white font-light">Mensagem:</p>
                <Input
                    placeholder="Mensagem"
                    className="bg-transparent placeholder-white rounded-full focus:border-2 focus:border-secondary-100"
                    {...register("message")}
                />
                {errors.message && errors.message.type === "too_small" && (
                    <span className="text-primary-50">{errors.message.message}</span>
                )}
            </div>
            <div className="flex w-full justify-start items-center">
                <Button
                    variant="Submit"
                    className="w-40 font-semibold text-textlight text-white">
                    Enviar
                </Button>
            </div>
        </form>
    )
}