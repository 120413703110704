import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Logo from "../../assets/Interas25Anos.png";
import FunctionMenu from './functionMenu';
import MobileMenu from "./functionMobileMenu";

import { ChevronDown, Headset } from 'lucide-react';
import Brasil from '../../assets/Brasil.png'
import IconInteras from '../../assets/Logo_Circle_Interas.png'

export default function Head() {

  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1)
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  return (
    <div
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="800"
      data-aos-delay="200"
      className="flex flex-col w-full justify-center items-center sticky top-0 z-50 dark:bg-gray-800">
      <div className='flex w-full h-20 justify-around items-center bg-primary-900'>
        <div className='flex flex-row-reverse lg:flex-row w-full lg:w-auto h-auto justify-between items-center'>
          <a className="flex-none text-xl font-semibold px-5 lg:px-0" href="/">
            <img src={Logo} className="lg:w-52 w-36" alt="Logo" />
          </a>
          <MobileMenu />
        </div>
        <div className='hidden lg:flex'>
          <FunctionMenu />
        </div>
      </div>
      {/* <div className='flex w-full h-10 bg-secondary-100 justify-center items-center'>
        <div className='flex w-full lg:w-9/12 justify-end px-4 lg:px-14 gap-x-5 lg:gap-x-16'>
          <div className='flex w-auto h-auto justify-center items-center gap-x-2'>
            <img src={IconInteras} className=' w-6 h-6' alt="" />
            <Link to="https://interas.com.br/BaixarApp/" className='text-white hover:underline'>Interas HelpDesk</Link>
          </div>
          <div className='flex w-auto h-auto justify-center items-center gap-x-2'>
            <Headset color="white" />
            <Link to="https://wa.me/558440420130" target='blank' className='text-white hover:underline'>Suporte</Link>
          </div>
          <div className='flex w-auto h-auto justify-center items-center gap-x-2'>
            <img src={Brasil} className=' w-6 h-4' alt="" />
            <p className='text-white'>PT</p>
            <ChevronDown color="white" />
          </div>
        </div>
      </div> */}
    </div>

  )
}

