// @ts-ignore
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { useRef } from 'react'
import { Button } from '../ui/button'

import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { CaseSlides } from 'src/Utils/useCasesSlides'

export default function CaseSucesso() {

    const splideRef = useRef<Splide | null>(null);
    const isMobile = useMediaQuery({ maxWidth: 800 })
    const valueScreen = isMobile ? '44rem' : '28rem'
    const paginationValue = isMobile ? false : true

    return (
        <div
            className="
            font-poppinsw-full flex lg:flex-col flex-col justify-evenly  items-center h-auto transition-all lg:p-20 lg-pt-0 pt-10 space-y-20
            "
            id='parceiros'
        >
            <div className='flex flex-col lg:w-[800px] w-full lg:p-0 p-4 lg:pb-0 pb-32 justify-center items-center text-center gap-5'>
                <h1 className='text-secondy xl:text-title text-primary-800 font-bold'>Cases de sucesso</h1>
                <h4 className='text-textlight xl:text-textmin 2xl:text-light text-secondary-100 font-light'>
                    Soluções de alta performace para seu negócio,
                    consultoria tecnológica desde a pesquisa à execução. Garantia de sucesso ao seu projeto!
                </h4>
            </div>
            <div className='flex lg:flex-row flex-col justify-center items-center lg:w-[1050px] w-[350px] lg:h-auto h-[70vh] p-4 rounded-3xl bg-primary-900'>
                <Splide
                    ref={splideRef}
                    options={{
                        rewind: true,
                        direction: 'ttb',
                        height: valueScreen,
                        wheel: false,
                        Button: false,
                        speed: 800,
                        drag: false,
                        autoplay: true,
                        interval: 3000,
                        easing: 'cubic-bezier(1, 0.45, 0.30, 0)',
                        arrows: false,
                        pagination: paginationValue,
                    }}
                >
                    {CaseSlides.map((itens, index) => (
                        <SplideSlide key={index} className="flex lg:flex-row flex-col w-full justify-center items-center gap-20 fade-transition">
                            <div className='flex justify-center items-center lg:w-[380px] w-[320px] lg:h-[350px] h-[350px]'>
                                <img src={itens.images} alt="images" width="100%" />
                            </div>
                            <div className='flex flex-col justify-center lg:items-start items-center lg:w-[400px] w-[350px] lg:h-[450px] h-[900px] lg:mb-0 mb-32 lg:mr-72 mr-0 lg:gap-5 gap-0'>
                                <div className='flex flex-col justify-center lg:items-start items-center lg:w-[580px] w-[300px] h-full gap-5'>
                                    <h1 className='text-description xl:text-secondy text-white font-bold'>{itens.title}</h1>
                                    <h4 className='text-light text-center lg:text-start font-extralight text-white'>{itens.description}</h4>
                                    <Link to={itens.link} target="_blank" rel="noopener noreferrer">
                                        <Button variant="default">
                                            <h3 className=' text-textmin'>Saiba mais</h3>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    )
}