import IntroBrityRPA from "src/components/BrityRpaPage"
import AnalysisReports from "src/components/BrityRpaPage/AnalysisReports"
import Contact from "src/components/BrityRpaPage/Contact"
import OurServices from "src/components/BrityRpaPage/OurServices"
import Resources from "src/components/BrityRpaPage/Resources"
import RelatedSolutions from "src/components/BrityRpaPage/RelatedSolutions"
import UseCase from "src/components/BrityRpaPage/UseCase"
import VideosPage from "src/components/BrityRpaPage/VideosPage"
import Characteristics from "src/components/BrityRpaPage/characteristics"
import SmoothScroll from "src/components/SmoothScroll"

function BrityRPA() {

    return (
        <>
            <SmoothScroll>
                <IntroBrityRPA />
                <OurServices />
                <VideosPage />
                <Characteristics />
                <AnalysisReports />
                <UseCase />
                <Resources />
                <RelatedSolutions />
                <Contact />
            </SmoothScroll>
        </>
    )
}

export default BrityRPA