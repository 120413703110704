import { useEffect, useState } from 'react'

import bgWebPortal from '../../assets/bgWebPortal.png'
import bgWebPortalMobile from '../../assets/bgWebPortalMobile.png'

import Data from '../../assets/Data.svg'
import Document from '../../assets/Document.svg'
import Check from '../../assets/check.svg'
import Pincel from '../../assets/pincel.svg'
import Screen from '../../assets/screen.svg'
import Settings from '../../assets/settings.svg'
import Storage from '../../assets/storage.svg'
import Support from '../../assets/support.svg'

export default function WebPortal() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [bgImage, setBgImage] = useState(bgWebPortal)

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth

            if (isMobile < 519) {
                setBgImage(bgWebPortalMobile)
            } else {
                setBgImage(bgWebPortal)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const DetailsWebPortal = [
        {
            iconeImage: Check,
            title: 'Licenças Ilimitadas',
            description: `Com o Cloud Backup as licenças são ilimitadas, pague apenas pelo giga contratado.`,
        },
        {
            iconeImage: Settings,
            title: 'Gerenciamento centralizado',
            description: `Administre as contas de seus clientes de forma centralizada, defina senhas, atribua cotas e extraia relatórios para controle e auditoria.`,
        },
        {
            iconeImage: Pincel,
            title: 'Personalização',
            description: `Administre as contas de seus clientes de forma centralizada, defina senhas, atribua cotas e extraia relatórios para controle e auditoria.`,
        },
        {
            iconeImage: Screen,
            title: 'Treinamentos gratuitos',
            description: `Conte com treinamentos gratuitos sobre conceitos de backup, segurança da informação, vendas e marketing.`,
        },
        {
            iconeImage: Storage,
            title: 'Storage on demand',
            description: `Flexibilidade para aumentar ou diminuir o seu volume de storage a qualquer momento.`,
        },
        {
            iconeImage: Support,
            title: 'Suporte especializado',
            description: `Temos uma equipe técnica e comercial para acompanhar a sua jornada de crescimento.`,
        },
        {
            iconeImage: Data,
            title: 'Datacenter padrão Tier III no Brasil',
            description: `Os dados são armazenados em datacenters certificados, localizados no Brasil e atendendo as máximas exigências de segurança e auditoria.`,
        },
        {
            iconeImage: Document,
            title: 'Contrato mensal',
            description: `Utilize os recursos da plataforma e pague somente após 30 dias. O contrato é mensal sem planos de fidelidade.`,
        },
    ]

    return (
        <div className="flex flex-col w-full lg:p-10 p-2 h-auto justify-center items-center gap-y-10">
            <h1 className="text-min lg:text-title font-bold tracking-tight text-primary-900 text-center">Receba um portal web<br />totalmente personalizável</h1>
            <h4 className="text-textlight lg:text-description font-regular tracking-tight text-secondary-100">com o nome, marca e logotipo da sua empresa!</h4>
            <div className='flex w-full h-auto rounded-[40px] justify-center items-center' style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', height: isMobile ? 'auto' : '130vh', backgroundRepeat: 'no-repeat' }}>
                <div className='flex flex-wrap w-auto max-w-[1280px] lg:justify-start justify-center items-center gap-10'>
                    {DetailsWebPortal.map((item, index) => (
                        <div
                            data-aos="fade-zoom-in"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="500"
                            data-aos-offset="0"
                            key={index}
                            className="flex flex-col justify-center items-start max-w-[380px] h-60 lg:p-0 p-5 lg:mt-5 mt-0 lg:gap-y-5 gap-y-2">
                            <img width="10%" src={item.iconeImage} alt="icon" />
                            <h2 className="text-description font-medium tracking-tight text-white leading-[36px]">{item.title}</h2>
                            <h4 className="text-textmin font-light tracking-tight text-justify text-white">{item.description}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}