import Contato from "src/components/Contato"
import IntroServerPage from "src/components/ServerPage"
import CloudServices from "src/components/ServerPage/cloud-services"
import CloudExclusiveAdvantages from "src/components/ServerPage/exclusive-advantages"
import CloudSimulation from "src/components/ServerPage/simulation"

function ServerPage() {

    return (
        <>
                <IntroServerPage />
                <CloudServices />
                <CloudExclusiveAdvantages />
                <CloudSimulation />
                <Contato />
        </>
    )
}

export default ServerPage