import { IoIosPlay } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "../ui/button";

import bgVideo from '../../assets/bgVideo.png'
import { Dialog, DialogTrigger, DialogContent } from "../ui/dialog"
import { Link } from "react-router-dom";
import VideosComponents from "./VideosComponents";

export default function VideosPage() {

    const videoId = '8-tKe0Mzf9w'

    return (
        <div className="flex flex-col lg:flex-row bg-gray-100 w-full h-auto justify-center items-center py-20 gap-y-10 lg:gap-y-0 gap-x-10">
            <div className="flex flex-col w-full lg:w-5/12 justify-start items-start gap-y-5">
                <p className="text-textmin font-semibold text-primary-900 font-regular px-5 lg:px-0">Vídeos</p>
                <div className="flex w-full justify-center items-center overflow-hidden">
                    <img
                        src={bgVideo}
                        alt="bgVideo"
                        style={{
                            filter: 'brightness(59%)',
                            marginLeft: '82px'
                        }}
                    />
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button
                                variant="decorated"
                                className="relative right-[28vh] lg:right-[48vh] border-white rounded-full p-10"
                            >
                                <span className="relative z-10"><IoIosPlay color="white" size={30} /></span>
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="flex w-full justify-center border-none">
                            <VideosComponents videoId={videoId} />
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
            <div className="flex flex-col full lg:w-4/12 justify-center items-start px-5 lg:px-0 gap-y-3">
                <h3 className="text-textlight lg:text-description font-bold tracking-tight text-primary-900">Veja como a conversação de IA transforma o ambiente de trabalho</h3>
                <h4 className="text-textlight font-regular tracking-tight text-primary-900">Brity RPA automatiza as tarefas requisitadas através de linguagem natural. Descubra como a solução funciona na prática.</h4>
                <Button variant="link" className="text-secondary-100 p-0">
                    <Link to="https://www.youtube.com/watch?v=8-tKe0Mzf9w" target="_blank">
                        <p className="text-textmin text-secondary-100 font-regular">Assistir ao vídeo</p>
                    </Link>
                    <IoIosArrowForward className="text-secondary-100" />
                </Button>
            </div>
        </div>
    )
}