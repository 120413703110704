import { contentCards } from "src/Utils/useContentCards";

interface Props {
    categoryKey: string
}

export default function CategoryCards({categoryKey}: Props) {
    return (
        <div className="flex flex-wrap w-full h-auto justify-around xl:justify-center items-center gap-x-0 lg:gap-x-4">
            {contentCards[categoryKey as keyof typeof contentCards].map((item, index) => (
                <div key={index} className="flex flex-col w-48 xl:w-60 h-[25rem] xl:h-96 justify-start items-center p-0 rounded-3xl card-gradient mb-4 py-4 lg:py-8">
                    <div className="w-32 h-32 rounded-full bg-black bg-opacity-50 shadow-inner">
                        <img src={item.image} alt={item.title} />
                    </div>
                    <h1 className="mt-2 text-min text-white font-semibold">{item.title}</h1>
                    <span className="mt-2 border-t-2 border-x-2 w-32 border-opacity-10 border-gray-100" />
                    <div className="w-10/12 mt-2">
                        <h3 className="text-minlight text-justify text-white font-normal">
                            {item.description}
                        </h3>
                    </div>
                </div>
            ))}
        </div>
    )
}