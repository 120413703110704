import React, { useEffect } from "react";
import { Button } from "../ui/button";
import Typed from "typed.js";
import BackgrounDev from "../../assets/BackgroundDev.png";
import BackgrounDevMobile from "../../assets/backgroundMobileDev.png"
import { FaArrowDown } from "react-icons/fa6";
import AppCarousel from "./apps-carousel";

export default function IntroDevPage() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isMobile = window.innerWidth <= 768

  const el = React.useRef(null)

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Criamos Aplicativos Incríveis",
        "Criamos RPAs Eficientes",
        "Criamos Plataformas Modernas",
      ],
      typeSpeed: 50,
      backSpeed: 0,
      fadeOut: true,
      loop: true,
      showCursor: false,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, [])

  return (
    <div className="h-auto w-full lg:h-screen flex flex-col lg:flex-row bg-no-repeat"
      style={{
        backgroundImage: `url(${isMobile ? BackgrounDevMobile : BackgrounDev})`,
        backgroundSize: "cover",
        zIndex: '10'
      }}
    >
      <div
        className="w-full lg:w-[100%] flex flex-col justify-center items-center"
      >
        <div className="w-full lg:w-2/4 h-[40rem] lg:h-auto flex flex-col justify-center items-center">
          <div className="flex flex-col gap-16">
            <span className="text-6xl h-40 w-44 font-bold text-primary-900" ref={el} />
            <div className="flex flex-col items-center space-y-2">
              <a href="#servicos">
                <Button variant="default" className="font-semibold text-md w-80">
                  Conheça nossas soluções
                </Button>
              </a>
              <FaArrowDown color="#0061FE" size={30} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row w-[100%] justify-center"
      >
        <AppCarousel />
      </div>
    </div>
  );
}
